import { NextSeo } from 'next-seo';
import Button from '../components/Button';
import RandomGeko from '../components/RandomGeko';
import { config } from '../config';

export default function Home() {
  const description = `Gekofam is a unique Algorand NFT project that brings great value in the form of Art, Utility, and Community. As the name suggests, we are more than just collections, we are a Family.`;

  return (
    <>
      <NextSeo title={`Welcome to the ${config.siteName}`} description={description} />

      <div className='grid grid-cols-12 px-6 pt-24 xl:pt-48 2xl:pt-56'>
        <div className='col-span-12 lg:col-span-6 lg:col-start-6 xl:col-start-6 xl:col-span-5'>
          <h2 className='text-[48px] leading-tight md:text-[72px] md:leading-[76px] font-bold mb-2 text-text'>
            Welcome<br className='hidden md:inline'></br>{' '}
            <span className='hidden space-x-4 md:flex'>
              <span>to the</span>
              <img src='/gekofam-logo.png' alt={`${config.siteName} logo`} className='h-[70px] hidden md:block' />
            </span>
            <span className='md:hidden'>
              to the <span className='text-primary-500'>Gekofam</span>
            </span>
          </h2>

          <p className='text-xl leading-relaxed text-text-darker'>{description}</p>

          <div className='flex flex-col mt-8 space-y-5 lg:space-x-8 lg:flex-row lg:space-y-0'>
            <Button href='https://www.minthol.art/?search=geko' target={'_blank'}>
              <div className='flex items-center'>
                <span>Join the Gekofam</span>
                <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' fill='currentColor' className='ml-2 w-[12px] h-[12px]'>
                  <path d='M384 320c-17.67 0-32 14.33-32 32v96H64V160h96c17.67 0 32-14.32 32-32s-14.33-32-32-32L64 96c-35.35 0-64 28.65-64 64V448c0 35.34 28.65 64 64 64h288c35.35 0 64-28.66 64-64v-96C416 334.3 401.7 320 384 320zM502.6 9.367C496.8 3.578 488.8 0 480 0h-160c-17.67 0-31.1 14.32-31.1 31.1c0 17.67 14.32 31.1 31.99 31.1h82.75L178.7 290.7c-12.5 12.5-12.5 32.76 0 45.26C191.2 348.5 211.5 348.5 224 336l224-226.8V192c0 17.67 14.33 31.1 31.1 31.1S512 209.7 512 192V31.1C512 23.16 508.4 15.16 502.6 9.367z' />
                </svg>
              </div>
            </Button>

            <Button href='/collections/geko' link theme='outline'>
              <div className='flex items-center'>
                <span>View Collection</span>
              </div>
            </Button>
          </div>
        </div>
      </div>

      <div className='md:fixed relative md:left-0 -bottom-2 mt-10 md:mt-0 z-10 w-[250px] md:w-[550px] lg:w-[400px] xl:w-[500px] 2xl:w-[575px] aspect-square'>
        <RandomGeko />
      </div>
    </>
  );
}
