import React, { useEffect, useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import useCountDown from 'react-countdown-hook';
import Image from 'next/image';

const numberOfGekoImages = 26;
const getRandomGeko = (current) => {
  // get random number between 1 and numberOfGekoImages
  const randomIndex = Math.floor(Math.random() * numberOfGekoImages) + 1;
  if (randomIndex === current) {
    return getRandomGeko(current);
  }
  return randomIndex;
};

function RandomGeko() {
  const initialTime = 120 * 1000;
  const [timeLeft, { start, pause, resume, reset }] = useCountDown(initialTime);
  const [index, setIndex] = useState(getRandomGeko());

  useEffect(() => {
    start();
  }, []);

  useEffect(() => {
    // for every 5 seconds, change the image
    if ((timeLeft / 1000) % 5 === 0) {
      setIndex(getRandomGeko(index));
    }
  }, [timeLeft]);

  return (
    <>
      {/* <AnimatePresence>
        <motion.div
          key={index}
          initial={{ opacity: 0, transform: 'translateX(-100%)' }}
          animate={{ opacity: 1, transform: 'translateX(0%)', transition: { duration: 0.75, delay: 0.75, ease: 'easeIn' } }}
          exit={{ opacity: 0, transform: 'translateX(-100%)' }}
          transition={{ duration: 0.75, ease: 'easeInOut' }}
          className='absolute inset-0 w-full h-full'
        >
          <Image src={`/images/home/${index}.png`} alt='Gekofam geko' width={750} height={750} />
        </motion.div>
      </AnimatePresence> */}
      <AnimatePresence>
        <motion.div key={index} initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} className='absolute inset-0 w-full h-full'>
          <Image src={`/images/home/${index}.png`} alt='Gekofam geko' width={750} height={750} />
        </motion.div>
      </AnimatePresence>
    </>
  );
}

export default RandomGeko;
