import Link from 'next/link';
import React from 'react';

function Button({ type = 'button', children, onClick, href, target, theme = 'primary', className, link }) {
  let themeClasses =
    'text-bg bg-primary-500 border-primary-500 hover:bg-transparent hover:border-primary-500 transition duration-300 hover:text-primary-500';

  if (theme === 'outline') {
    themeClasses = 'text-primary-500 border-primary-500 hover:bg-primary-500 hover:text-bg';
  }

  const classes = `inline-flex items-center px-10 py-3 border-2 text-sm font-bold uppercase rounded-full transition-all duration-200 tracking-wide text-center justify-center ${themeClasses} ${className}`;

  if (link && href) {
    return (
      <Link href={href} target={target}>
        <a className={classes}>{children}</a>
      </Link>
    );
  }

  if (href) {
    return (
      <a href={href} target={target} className={classes}>
        {children}
      </a>
    );
  }

  return (
    <button type={type} onClick={onClick} className={classes}>
      {children}
    </button>
  );
}

export default Button;
